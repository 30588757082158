<template>
  <div class="container" style="padding: 20px">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-12">
        <h5>
          <router-link to="/">Trang chủ</router-link>>
          <router-link :to="`#`">Tìm kiếm</router-link>
        </h5>
        <hr />
        <h3 style>Kết quả trùng khớp với '{{ search }}'</h3>
        <div class="row" style="max-height: 800px;; overflow-y: auto;">
          <div
            v-for="(manga, index) in mangas"
            :key="index"
            class="col-lg-3 col-md-6 col-sm-6 col-6"
            style="padding: 10px;"
          >
            <div class="card" style="width: 100%; height: 100%;">
              <router-link :to="`/truyen/${manga.name}`">
                <img
                  :src="`/images/${manga.path}`"
                  alt="manga.alt"
                  style="width: 100%; height: 200px;"
                />
              </router-link>
              <div class="card-body">
                <h5 class="card-title tooltip-trigger">
                  <router-link :to="`/truyen/${manga.name}`">
                    {{
                    truncatedName(manga.name)
                    }}
                  </router-link>
                </h5>
                <p class="card-text">Chap {{ manga.totalChapters }}</p>
                <router-link :to="`/truyen/${manga.name}`" class="btn btn-primary">
                  Đọc
                  ngay
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <TheRight />
        <!-- <TheRight2 /> -->
      </div>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
import TheRight from "./TheRight.vue";
// import TheRight2 from "./TheRight2.vue";
export default {
  components: {
    TheRight
    // TheRight2
  },
  data() {
    return {
      mangas: [],
      search: this.$route.params.id
    };
  },
  created() {
    this.fetchMangaData(this.search);
  },
  watch: {
    "$route.params.id": {
      immediate: true, // Để chạy ngay lập tức khi component được tạo
      handler(newName) {
        // Xử lý khi params thay đổi, ví dụ:
        this.search = newName;
        this.fetchMangaData(this.search); // Gọi hàm để fetch dữ liệu mới
      }
    }
  },
  mounted() {
    this.fetchMangaData(this.search);
  },
  methods: {
    fetchMangaData(id) {
      axios
        .get(`https://chaosquare.shop/api/search/${id}`)
        .then(response => {
          this.mangas = response.data;
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            // Chuyển hướng đến trang lỗi
          } else {
            console.error("Error fetching manga:", error);
          }
        });
    },
    truncatedName(name) {
      if (name.length > 20) {
        return name.substring(0, 20) + "...";
      }
      return name;
    }
  }
};
</script>
  <style>
</style>