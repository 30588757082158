<!-- src/components/Navbar.vue -->
<template>
  <nav
    class="navbar navbar-expand-lg"
    style="background: rgb(1,204,157); background: linear-gradient(90deg, rgba(1,204,157,1) 0%, rgba(166,3,201,1) 100%);"
  >
    <div class="container-fluid" style="padding-left: 50px; padding-right: 50px;">
      <router-link to="/">
        <img
          class="navbar-brand mx-auto"
          src="/meof.png"
          style="width: 200px; height: 51px;"
          alt="meof"
        />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link active" aria-current="page" to="/">
              Trang
              chủ
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/tac-gia">Tác giả</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/theo-doi">Theo dõi</router-link>
          </li>
          <li class="nav-item dropdown" @mouseover="showDropdownMenu" @mouseout="hideDropdownMenu">
            <router-link
              class="nav-link dropdown-toggle"
              to="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >Thể loại truyện</router-link>
            <ul
              class="dropdown-menu dropdown-hidden"
              style="min-width: 300px; background: rgb(1,204,157); background: linear-gradient(90deg, rgba(1,204,157,1) 49%, rgba(166,3,201,1) 100%);"
            >
              <div class="row">
                <div class="col-lg-3 col-md-6 col-12">
                  <li>
                    <router-link class="nav-link" to="/the-loai/tat-ca">Tất cả</router-link>
                  </li>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                  <li>
                    <router-link class="nav-link" to="/the-loai/kinh-di">Kinh dị</router-link>
                  </li>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                  <li>
                    <router-link class="nav-link" to="/the-loai/bach-hop">Bách hợp</router-link>
                  </li>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                  <li>
                    <router-link class="nav-link" to="/the-loai/boy-love">Boy love</router-link>
                  </li>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-6 col-12">
                  <li>
                    <router-link class="nav-link" to="/the-loai/tinh-cam">Tình cảm</router-link>
                  </li>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                  <li>
                    <router-link class="nav-link" to="/the-loai/hai-huoc">Hài hước</router-link>
                  </li>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                  <li>
                    <router-link class="nav-link" to="/the-loai/co-trang">Cổ trang</router-link>
                  </li>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                  <li>
                    <router-link class="nav-link" to="/the-loai/kiem-hiep">Kiếm hiệp</router-link>
                  </li>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-6 col-12">
                  <li>
                    <router-link class="nav-link" to="/the-loai/huyen-huyen">Huyền huyễn</router-link>
                  </li>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                  <li>
                    <router-link class="nav-link" to="/the-loai/trinh-tham">Trinh thám</router-link>
                  </li>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                  <li>
                    <router-link class="nav-link" to="/the-loai/nu-phu">Nữ phụ</router-link>
                  </li>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                  <li>
                    <router-link class="nav-link" to="/the-loai/xuyen-khong">Xuyên không</router-link>
                  </li>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-6 col-12">
                  <li>
                    <router-link class="nav-link" to="/the-loai/vien-tuong">Viễn tưởng</router-link>
                  </li>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                  <li>
                    <router-link class="nav-link" to="/the-loai/viet-nam">Việt Nam</router-link>
                  </li>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                  <li>
                    <router-link class="nav-link" to="/the-loai/khac">Khác</router-link>
                  </li>
                </div>
                <!-- Thêm các mục khác tương tự -->
              </div>
            </ul>
          </li>
        </ul>
        <template v-if="!username">
          <button class="btn btn-outline-success; font-color: black" type="button" @click="login">
            Đăng
            nhập
          </button>/
          <button
            class="btn btn-outline-success; font-color: black"
            type="button"
            @click="register"
          >
            Đăng
            ký
          </button>
        </template>
        <template v-else>
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li
              class="nav-item dropdown"
              @mouseover="showDropdownMenu"
              @mouseout="hideDropdownMenu"
            >
              <router-link
                class="nav-link dropdown-toggle"
                to="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >Xin chào {{ username }}</router-link>
              <ul
                class="dropdown-menu dropdown-hidden"
                style="background: rgb(1,204,157); background: linear-gradient(90deg, rgba(1,204,157,1) 49%, rgba(166,3,201,1) 100%);"
              >
                <li>
                  <router-link class="dropdown-item" to="#">Hồ sơ</router-link>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a class="dropdown-item" @click="logout">Đăng xuất</a>
                </li>
              </ul>
            </li>
          </ul>
        </template>
        <form class="d-flex" role="search" @submit.prevent="redirectToIdCheck">
          <input
            class="form-control"
            type="search"
            placeholder="Nhập tên người dùng"
            aria-label="Search"
            v-model="usernameInput"
          />
          <button type="submit" class="btn btn-outline-success">
            <span>&#128269;</span>
          </button>
        </form>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "TheNavbar",
  data() {
    return {
      username: "", // Cập nhật giá trị username từ store hoặc API
      usernameInput: ""
    };
  },
  created() {
    if (localStorage.getItem("username")) {
      this.username = localStorage.getItem("username");
    } else {
      this.username = "";
    }
  },
  methods: {
    checkUsername() {
      if (!this.username) {
        alert("Vui lòng đăng nhập để truy cập trang này.");
        return false;
      }
      return true;
    },
    redirectToIdCheck() {
      this.$router.push(`/tim-kiem/${this.usernameInput}`);
    },
    login() {
      // Logic đăng nhập
      this.$router.push("/dang-nhap");
    },
    logout() {
      // Logic đăng xuất
      localStorage.removeItem("username");
      alert("Bạn đã đăng xuất.");
      window.location.reload();
    },
    register() {
      // Logic đăng ký
      this.$router.push("/dang-ky");
    },
    showDropdownMenu() {
      // Logic hiển thị menu dropdown
    },
    hideDropdownMenu() {
      // Logic ẩn menu dropdown
    }
  }
};
</script>
