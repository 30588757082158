<template>
  <div class="container" style="padding: 20px">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-12">
        <h5>
          <router-link to="/">Trang chủ</router-link>>
          <router-link :to="`/tac-gia/${authorName}`">
            Tác giả {{
            authorName }}
          </router-link>
        </h5>
        <hr />
        <h3 id="new">Tác phẩm đang theo dõi</h3>
        <div class="row">
          <div
            v-for="(manga, index) in paginatedManga"
            :key="index"
            class="col-lg-3 col-md-6 col-sm-6 col-6"
            style="padding: 10px;"
          >
            <div class="card" style="width: 100%; height: 100%;">
              <router-link :to="`/truyen/${manga.name}`">
                <img
                  :src="`/images/${manga.path}`"
                  alt="manga.alt"
                  style="width: 100%; height: 200px;"
                />
              </router-link>
              <div class="card-body">
                <h5 class="card-title tooltip-trigger">
                  <router-link :to="`/truyen/${manga.name}`">
                    {{
                    truncatedName(manga.name)
                    }}
                  </router-link>
                </h5>
                <p class="card-text">Chap {{ manga.chap }}</p>
                <button
                  type="button"
                  class="btn btn-danger"
                  @click="unfollow(manga.name)"
                >Hủy theo dõi</button>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <button type="button" @click="prevPageAndScroll" :disabled="currentPage === 1">Previous</button>
          <span v-for="page in displayPages" :key="page">
            <button
              v-if="page !== '...'"
              type="button"
              @click="goToPage(page)"
              :class="{ active: page === currentPage }"
            >{{ page }}</button>
            <span v-else>...</span>
          </span>
          <button
            type="button"
            @click="nextPageAndScroll"
            :disabled="currentPage === totalPages"
          >Next</button>
        </div>
        <br />
        <br />
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <TheRight />
        <TheRight2 />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TheRight from "./TheRight.vue";
import TheRight2 from "./TheRight2.vue";
export default {
  components: {
    TheRight,
    TheRight2
  },
  data() {
    return {
      mangas: [],
      currentPage: 1,
      itemsPerPage: 12
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.mangas.length / this.itemsPerPage);
    },
    paginatedManga() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.mangas.slice(start, end);
    },
    displayPages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;
      let pages = [];
      let startPage = 1;
      let endPage = totalPages;
      const maxVisiblePages = 5; // Số lượng trang hiển thị tối đa

      if (totalPages > maxVisiblePages) {
        if (currentPage <= 3) {
          endPage = maxVisiblePages;
        } else if (currentPage >= totalPages - 2) {
          startPage = totalPages - maxVisiblePages + 1;
        } else {
          startPage = currentPage - 2;
          endPage = currentPage + 2;
        }
      }

      if (startPage !== 1) {
        pages.push(1);
        if (startPage > 2) {
          pages.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage !== totalPages) {
        if (endPage < totalPages - 1) {
          pages.push("...");
        }
        pages.push(totalPages);
      }

      return pages;
    }
  },
  created() {
    document.title = "Truyện chữ nét | Theo dõi";
    if (!localStorage.getItem("username")) {
      alert("Bạn phải đăng nhập để truy cập trang này!");
      this.$router.push("/dang-nhap");
    }
    axios
      .post(
        "https://chaosquare.shop/api/get-follow",
        {
          username: localStorage.getItem("username")
        },
        {
          headers: {
            "X-CSRF-TOKEN": document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content")
          }
        }
      )
      .then(response => {
        this.mangas = response.data;
      })
      .catch(error => {
        // Xử lý lỗi nếu có
        console.error("Error sending POST request:", error);
      });
  },
  mounted() {},
  methods: {
    truncatedName(name) {
      if (name.length > 20) {
        return name.substring(0, 20) + "...";
      }
      return name;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      if (page === "...") return;
      this.currentPage = page; // Cập nhật currentPage
      this.scrollToNew();
    },
    nextPageAndScroll() {
      this.nextPage();
      this.scrollToNew();
    },
    prevPageAndScroll() {
      this.prevPage();
      this.scrollToNew();
    },
    scrollToNew() {
      const element = document.getElementById("new");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    unfollow(name) {
      axios
        .post(
          "https://chaosquare.shop/api/unfollow",
          {
            username: localStorage.getItem("username"),
            mangaName: name
          },
          {
            headers: {
              "X-CSRF-TOKEN": document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content")
            }
          }
        )
        .then(response => {
          if (response.data.success) {
            axios
              .post(
                "https://chaosquare.shop/api/get-follow",
                {
                  username: localStorage.getItem("username")
                },
                {
                  headers: {
                    "X-CSRF-TOKEN": document
                      .querySelector('meta[name="csrf-token"]')
                      .getAttribute("content")
                  }
                }
              )
              .then(response => {
                this.mangas = response.data;
              })
              .catch(error => {
                // Xử lý lỗi nếu có
                console.error("Error sending POST request:", error);
              });
            alert("Hủy theo dõi thành công!");
          } else {
            alert("Hủy theo dõi thất bại!");
          }
        })
        .catch(error => {
          // Xử lý lỗi nếu có
          console.error(error);
        });
    }
  }
};
</script>
<style>
</style>