<template>
  <div class="container" style="padding: 20px">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-12">
        <h5>
          <router-link to="/">Trang chủ</router-link>>
          <router-link to="`#`">Thể loại</router-link>
        </h5>
        <hr />
        <h3 id="new">Thể loại truyện: {{ tittle }}</h3>
        <i>{{ description }}</i>
        <div class="row">
          <div
            v-for="(manga, index) in paginatedManga"
            :key="index"
            class="col-lg-3 col-md-6 col-sm-6 col-6"
            style="padding: 10px;"
          >
            <div class="card" style="width: 100%; height: 100%;">
              <router-link :to="`/truyen/${manga.name}`">
                <img
                  :src="`/images/${manga.path}`"
                  alt="manga.alt"
                  style="width: 100%; height: 200px;"
                />
              </router-link>
              <div class="card-body">
                <h5 class="card-title tooltip-trigger">
                  <router-link :to="`/truyen/${manga.name}`">
                    {{
                    truncatedName(manga.name)
                    }}
                  </router-link>
                </h5>
                <p class="card-text">Chap {{ manga.totalChapters }}</p>
                <router-link :to="`/truyen/${manga.name}`" class="btn btn-primary">
                  Đọc
                  ngay
                </router-link>
              </div>
            </div>
          </div>
          <div class="pagination">
            <button type="button" @click="prevPageAndScroll" :disabled="currentPage === 1">Previous</button>
            <span v-for="page in displayPages" :key="page">
              <button
                v-if="page !== '...'"
                type="button"
                @click="goToPage(page)"
                :class="{ active: page === currentPage }"
              >{{ page }}</button>
              <span v-else>...</span>
            </span>
            <button
              type="button"
              @click="nextPageAndScroll"
              :disabled="currentPage === totalPages"
            >Next</button>
          </div>
        </div>
        <br />
        <br />
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <TheRight />
        <TheRight2 />
      </div>
    </div>
  </div>
</template>
    
<script>
import axios from "axios";
import TheRight from "./TheRight.vue";
import TheRight2 from "./TheRight2.vue";
export default {
  components: {
    TheRight,
    TheRight2
  },
  data() {
    return {
      mangas: [],
      type: this.$route.params.type,
      tittle: "",
      description: "",
      currentPage: 1,
      itemsPerPage: 12
    };
  },
  created() {
    this.fetchMangaData(this.type);
    document.title = "Truyện chữ nét | " + this.tittle;
  },
  mounted() {
    this.fetchMangaData(this.type);
  },
  watch: {
    "$route.params.type": {
      immediate: true, // Để chạy ngay lập tức khi component được tạo
      handler(newName) {
        // Xử lý khi params thay đổi, ví dụ:
        this.type = newName;
        this.fetchMangaData(this.type); // Gọi hàm để fetch dữ liệu mới
      }
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.mangas.length / this.itemsPerPage);
    },
    paginatedManga() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.mangas.slice(start, end);
    },
    displayPages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;
      let pages = [];
      let startPage = 1;
      let endPage = totalPages;
      const maxVisiblePages = 5; // Số lượng trang hiển thị tối đa

      if (totalPages > maxVisiblePages) {
        if (currentPage <= 3) {
          endPage = maxVisiblePages;
        } else if (currentPage >= totalPages - 2) {
          startPage = totalPages - maxVisiblePages + 1;
        } else {
          startPage = currentPage - 2;
          endPage = currentPage + 2;
        }
      }

      if (startPage !== 1) {
        pages.push(1);
        if (startPage > 2) {
          pages.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage !== totalPages) {
        if (endPage < totalPages - 1) {
          pages.push("...");
        }
        pages.push(totalPages);
      }

      return pages;
    }
  },
  methods: {
    fetchMangaData(type) {
      axios
        .post(`https://chaosquare.shop/api/type/${type}`)
        .then(response => {
          if (response.data.success == false) {
            this.$router.push("/error");
          } else {
            this.mangas = response.data.mangas;
            this.tittle = response.data.type;
            document.title = "Truyện chữ nét | " + this.tittle;
            this.description = response.data.description;
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            // Chuyển hướng đến trang lỗi
          } else {
            console.error("Error fetching manga:", error);
          }
        });
    },
    truncatedName(name) {
      if (name.length > 20) {
        return name.substring(0, 20) + "...";
      }
      return name;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      if (page === "...") return;
      this.currentPage = page; // Cập nhật currentPage
      this.scrollToNew();
    },
    nextPageAndScroll() {
      this.nextPage();
      this.scrollToNew();
    },
    prevPageAndScroll() {
      this.prevPage();
      this.scrollToNew();
    },
    scrollToNew() {
      const element = document.getElementById("new");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }
};
</script>
    <style>
</style>