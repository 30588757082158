<template>
  <div class="container" style="padding: 20px">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-12">
        <h5>
          <router-link to="/">Trang chủ</router-link>>
          <router-link to="#">Tác giả</router-link>
        </h5>
        <hr />
        <h3 id="new">Danh sách tác giả:</h3>
        <table class="table table-striped" style="width: 100%">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">
                <button type="button" class="btn btn-link" @click="sortBy('username')">Tên tác giả</button>
              </th>
              <th scope="col">
                <button type="button" class="btn btn-link" @click="sortBy('totalMangas')">Tác phẩm</button>
              </th>
              <th scope="col">
                <button type="button" class="btn btn-link" @click="sortBy('likes')">Lượt theo dõi</button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(author, index) in paginatedAuthors" :key="author.id">
              <th scope="row">{{ (currentPage - 1) * itemsPerPage + index + 1 }}</th>
              <td>
                <router-link :to="`/tac-gia/${author.username}`">{{ author.username }}</router-link>
              </td>
              <td>{{ author.totalMangas }}</td>
              <td>{{ author.likes }}</td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button type="button" @click="prevPageAndScroll" :disabled="currentPage === 1">Previous</button>
          <span v-for="page in displayPages" :key="page">
            <button
              v-if="page !== '...'"
              type="button"
              @click="goToPage(page)"
              :class="{ active: page === currentPage }"
            >{{ page }}</button>
            <span v-else>...</span>
          </span>
          <button
            type="button"
            @click="nextPageAndScroll"
            :disabled="currentPage === totalPages"
          >Next</button>
        </div>
        <br />
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <TheRight />
      </div>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
import TheRight from "./TheRight.vue";

export default {
  components: {
    TheRight
  },
  data() {
    return {
      authors: [],
      currentPage: 1,
      itemsPerPage: 10,
      click1: 0,
      click2: 0,
      click3: 0,
      sort: 0
    };
  },
  created() {
    this.fetchAuthorData();
    document.title = "Truyện chữ nét | Tác giả";
  },
  computed: {
    totalPages() {
      return Math.ceil(this.authors.length / this.itemsPerPage);
    },
    paginatedAuthors() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.authors.slice(start, end);
    },
    displayPages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;
      let pages = [];
      let startPage = 1;
      let endPage = totalPages;
      const maxVisiblePages = 5; // Số lượng trang hiển thị tối đa

      if (totalPages > maxVisiblePages) {
        if (currentPage <= 3) {
          endPage = maxVisiblePages;
        } else if (currentPage >= totalPages - 2) {
          startPage = totalPages - maxVisiblePages + 1;
        } else {
          startPage = currentPage - 2;
          endPage = currentPage + 2;
        }
      }

      if (startPage !== 1) {
        pages.push(1);
        if (startPage > 2) {
          pages.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage !== totalPages) {
        if (endPage < totalPages - 1) {
          pages.push("...");
        }
        pages.push(totalPages);
      }

      return pages;
    }
  },
  watch: {
    authors: {
      handler(newVal) {
        this.author = newVal;
        // Thực hiện các hành động khác khi mảng authors thay đổi
      },
      deep: true
    }
  },
  methods: {
    sortBy(key) {
      if (key == "username") {
        this.click1++;
        this.sort = this.click1;
      } else if (key == "totalMangas") {
        this.click2++;
        this.sort = this.click2;
      } else {
        this.sort = this.click3;
        this.click3++;
      }
      axios
        .post(`https://chaosquare.shop/api/sort-author/${key}/${this.sort}`)
        .then(response => {
          if (response.data.success == false) {
            this.$router.push("/error");
          } else {
            this.authors = response.data;
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            // Chuyển hướng đến trang lỗi
          } else {
            console.error("Error fetching manga:", error);
          }
        });
    },
    fetchAuthorData() {
      axios
        .post(`https://chaosquare.shop/api/all-author`)
        .then(response => {
          if (response.data.success == false) {
            this.$router.push("/error");
          } else {
            this.authors = response.data;
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            // Chuyển hướng đến trang lỗi
          } else {
            console.error("Error fetching manga:", error);
          }
        });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      if (page === "...") return;
      this.currentPage = page; // Cập nhật currentPage
      this.scrollToNew();
    },
    nextPageAndScroll() {
      this.nextPage();
      this.scrollToNew();
    },
    prevPageAndScroll() {
      this.prevPage();
      this.scrollToNew();
    },
    scrollToNew() {}
  }
};
</script>
  