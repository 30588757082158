import {
    createApp
} from 'vue';

import App from './App.vue';
import router from './router/index.js';
import axios from 'axios';
window.axios = axios;

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/global.css';

createApp(App).use(router).mount('#app');