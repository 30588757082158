// Import Vue Router và Vue
import {
    createRouter,
    createWebHistory
} from 'vue-router';
import MainPage from '../views/pages/MainPage.vue';
import RegisterPage from '../views/pages/RegisterPage.vue';
import LoginPage from '../views/pages/LoginPage.vue';
import MangaPage from '../views/pages/MangaPage.vue';
import ChapterPage from '../views/pages/ChapterPage.vue';
import AuthorPage from '../views/pages/AuthorPage.vue';
import FollowPage from '../views/pages/FollowPage.vue';
import AllAuthor from '../views/pages/AllAuthor.vue';
import ErrorPage from '../views/pages/ErrorPage.vue';
import SearchPage from '../views/pages/SearchPage.vue';
import TypeManga from '../views/pages/TypeManga.vue';

const routes = [{
        path: '/',
        name: 'MainPage',
        component: MainPage
    },
    {
        path: '/the-loai/:type',
        name: 'TypeManga',
        component: TypeManga
    },
    {
        path: '/tim-kiem/:id',
        name: 'SearchPage',
        component: SearchPage
    },
    {
        path: '/dang-nhap',
        name: 'LoginPage',
        component: LoginPage
    },
    {
        path: '/:catchAll(.*)',
        name: 'ErrorPage',
        component: ErrorPage
    },
    {
        path: '/dang-ky',
        name: 'RegisterPage',
        component: RegisterPage
    },
    {
        path: '/truyen/:name',
        name: 'MangaPage',
        component: MangaPage
    },
    {
        path: '/tac-gia/:author',
        name: 'AuthorPage',
        component: AuthorPage
    },
    {
        path: '/tac-gia',
        name: 'AllAuthor',
        component: AllAuthor
    },
    {
        path: '/theo-doi',
        name: 'FollowPage',
        component: FollowPage
    },
    {
        path: '/truyen/:name/:chap',
        name: 'ChapterPage',
        component: ChapterPage
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    }
});

export default router;