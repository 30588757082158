<template>
  <div class="container" style="padding: 20px">
    <div class="row">
      <div class="row">
        <!-- <div class="col-lg-1 col-md-0 col-0"></div> -->
        <div class="col-lg-12 col-md-0 col-0">
          <h5>
            <router-link :to="'#'">Truyện đề cử</router-link>
          </h5>
          <hr />
          <div id="carouselExample" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="d-flex">
                  <div
                    v-for="(manga, index) in mangarecommend.slice(0, 4)"
                    :key="index"
                    class="image-wrapper col-6 col-sm-6 col-md-4 col-lg-3"
                  >
                    <router-link :to="`/truyen/${manga.name}`">
                      <img
                        :src="`/images/${manga.path}`"
                        alt="manga.alt"
                        style="width: 270px; height: 340px;"
                      />
                    </router-link>
                    <div class="caption">{{ manga.name }}</div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="d-flex">
                  <div
                    v-for="(manga, index) in mangarecommend.slice(4, 8)"
                    :key="index"
                    class="image-wrapper col-6 col-sm-6 col-md-4 col-lg-3"
                  >
                    <router-link :to="`/truyen/${manga.name}`">
                      <img
                        :src="`/images/${manga.path}`"
                        alt="manga.alt"
                        style="width: 270px; height: 340px;"
                      />
                    </router-link>
                    <div class="caption">{{ manga.name }}</div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="d-flex">
                  <div
                    v-for="(manga, index) in mangarecommend.slice(8, 12)"
                    :key="index"
                    class="image-wrapper col-6 col-sm-6 col-md-4 col-lg-3"
                  >
                    <router-link :to="`/truyen/${manga.name}`">
                      <img
                        :src="`/images/${manga.path}`"
                        alt="manga.alt"
                        style="width: 270px; height: 340px;;"
                      />
                    </router-link>
                    <div class="caption">{{ manga.name }}</div>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="prev"
            >
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="next"
            >
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
          <hr />
        </div>
        <!-- <div class="col-lg-1 col-md-0 col-0"></div> -->
      </div>
      <div id="new" class="row">
        <div class="col-lg-8 col-md-8 col-12">
          <h5>
            <router-link :to="'#'">Truyện mới</router-link>
          </h5>
          <div class="row">
            <!-- Hiển thị danh sách truyện mới -->
            <div
              v-for="(manga, index) in paginatedManga"
              :key="index"
              class="col-lg-3 col-md-6 col-sm-6 col-6"
              style="padding: 10px;"
            >
              <div class="card" style="width: 100%; height: 100% ">
                <router-link :to="`/truyen/${manga.name}`">
                  <img
                    :src="`/images/${manga.path}`"
                    class="card-img-top"
                    alt="test.jpg"
                    style="width: 100%; height:200px"
                  />
                </router-link>
                <div class="card-body">
                  <h5 class="card-title tooltip-trigger">
                    <router-link :to="`/truyen/${manga.name}`">
                      {{
                      truncatedName(manga.name)
                      }}
                    </router-link>
                  </h5>
                  <p class="card-text">Chap {{ manga.totalChapters }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination">
            <button type="button" @click="prevPageAndScroll" :disabled="currentPage === 1">Previous</button>
            <span v-for="page in displayPages" :key="page">
              <button
                v-if="page !== '...'"
                type="button"
                @click="goToPage(page)"
                :class="{ active: page === currentPage }"
              >{{ page }}</button>
              <span v-else>...</span>
            </span>
            <button
              type="button"
              @click="nextPageAndScroll"
              :disabled="currentPage === totalPages"
            >Next</button>
          </div>
          <br />
          <br />
        </div>
        <div class="col-lg-4 col-md-4 col-12">
          <TheRight />
          <br />
          <br />
          <br />
          <TheRight2 />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheRight from "./TheRight.vue";
import TheRight2 from "./TheRight2.vue";
import axios from "axios";

export default {
  components: {
    TheRight,
    TheRight2
  },
  data() {
    return {
      mangarecommend: [],
      allmanga: [],
      currentPage: 1,
      itemsPerPage: 12,
      pageTitle: "default"
    };
  },
  watch: {
    pageTitle(newTitle) {
      document.title = newTitle;
    }
  },
  mounted() {
    this.fetchTopMangas();
    this.fetchMangas();
    this.pageTitle = "Truyện chữ nét";
  },
  computed: {
    totalPages() {
      return Math.ceil(this.allmanga.length / this.itemsPerPage);
    },
    paginatedManga() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.allmanga.slice(start, end);
    },
    displayPages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;
      let pages = [];
      let startPage = 1;
      let endPage = totalPages;
      const maxVisiblePages = 5; // Số lượng trang hiển thị tối đa

      if (totalPages > maxVisiblePages) {
        if (currentPage <= 3) {
          endPage = maxVisiblePages;
        } else if (currentPage >= totalPages - 2) {
          startPage = totalPages - maxVisiblePages + 1;
        } else {
          startPage = currentPage - 2;
          endPage = currentPage + 2;
        }
      }

      if (startPage !== 1) {
        pages.push(1);
        if (startPage > 2) {
          pages.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage !== totalPages) {
        if (endPage < totalPages - 1) {
          pages.push("...");
        }
        pages.push(totalPages);
      }

      return pages;
    }
  },
  methods: {
    fetchTopMangas() {
      axios
        .get("https://chaosquare.shop/api/recommend-mangas")
        .then(response => {
          this.mangarecommend = response.data;
        })
        .catch(error => {
          console.error("Error fetching recommend mangas:", error);
        });
    },
    fetchMangas() {
      axios
        .get("https://chaosquare.shop/api/all-mangas")
        .then(response => {
          this.allmanga = response.data;
        })
        .catch(error => {
          console.error("Error fetching all mangas:", error);
        });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    truncatedName(name) {
      if (name.length > 20) {
        return name.substring(0, 20) + "...";
      }
      return name;
    },
    goToPage(page) {
      if (page === "...") return;
      this.currentPage = page; // Cập nhật currentPage
      this.scrollToNew();
    },
    nextPageAndScroll() {
      this.nextPage();
      this.scrollToNew();
    },
    prevPageAndScroll() {
      this.prevPage();
      this.scrollToNew();
    },
    scrollToNew() {
      const element = document.getElementById("new");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }
};
</script>
<style>
.image-wrapper {
  margin-right: 10px;
  position: relative;
  display: inline-block;
}

.caption {
  align-items: center;
  height: 50px;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Optional: Điều chỉnh màu nền và độ trong suốt của phần chú thích */
  color: white;
  /* Optional: Màu văn bản */
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  /* Optional: Cỡ chữ */
}

/* Định dạng menu dropdown */
.dropdown-menu {
  display: none;
  /* Mặc định ẩn menu dropdown */
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100%;
  /* Độ rộng tối thiểu của menu */
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  float: left;
  /* Hiển thị các mục menu cạnh nhau */
  list-style-type: none;
  /* Ẩn dấu đầu dòng của mỗi mục menu */
}

a {
  text-decoration: none;
}

.dropdown-menu li a {
  display: block;
  color: black;
  text-align: center;
  padding: 12px 16px;
  text-decoration: none;
}

.dropdown-menu li a:hover {
  background-color: #f1f1f1;
}

/* Hiển thị menu dropdown khi hover vào menu cha */
.dropdown:hover .dropdown-menu {
  display: block;
}

.tooltip {
  width: 300%;
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 10px;
}

.card-title {
  min-height: 2.4em;
  /* Adjust as needed */
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card:hover {
  opacity: 0.7;
  /* Adjust the opacity value as needed */
}

.image-wrapper:hover {
  opacity: 0.7;
  /* Adjust the opacity value as needed */
}
</style>