<template>
  <div class="container" style="padding: 20px">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-12">
        <h5>
          <router-link to="/">Trang chủ</router-link>>
          <router-link :to="`/tac-gia/${authorName}`">
            Tác giả {{
            authorName }}
          </router-link>
        </h5>
        <hr />
        <h3 id="new">Tác phẩm của {{ authorName }}</h3>
        <div class="row">
          <div v-if="mangas.length === 0">
            <hr />
            <div style="align-items: center; justify-content: center; display: flex;">
              {{ authorName }} chưa
              có tác phẩm nào
            </div>
            <br />
          </div>
          <div
            v-for="(manga, index) in paginatedManga"
            :key="index"
            class="col-lg-3 col-md-6 col-sm-6 col-6"
            style="padding: 10px;"
          >
            <div class="card" style="width: 100%; height: 100%;">
              <router-link :to="`/truyen/${manga.name}`">
                <img
                  :src="`/images/${manga.path}`"
                  class="card-img-top"
                  alt="test.jpg"
                  style="width: 100%; height:200px"
                />
              </router-link>
              <div class="card-body">
                <h5 class="card-title tooltip-trigger">
                  <router-link :to="`/truyen/${manga.name}`">
                    {{
                    truncatedName(manga.name)
                    }}
                  </router-link>
                </h5>
                <p class="card-text">Chap {{ manga.chap }}</p>
                <router-link :to="`/truyen/${manga.name}`" class="btn btn-primary">
                  Đọc
                  ngay
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-if="mangas.length !== 0" class="pagination">
          <button type="button" @click="prevPageAndScroll" :disabled="currentPage === 1">Previous</button>
          <span v-for="page in displayPages" :key="page">
            <button
              v-if="page !== '...'"
              type="button"
              @click="goToPage(page)"
              :class="{ active: page === currentPage }"
            >{{ page }}</button>
            <span v-else>...</span>
          </span>
          <button
            type="button"
            @click="nextPageAndScroll"
            :disabled="currentPage === totalPages"
          >Next</button>
        </div>
        <!-- <TheComment /> -->
        <div>
          <h3>Bình luận:</h3>
          <form @submit.prevent="submitComment">
            <textarea
              v-model="newComment"
              rows="6"
              style="width: 100%;"
              placeholder="Nhập bình luận"
              required
            ></textarea>
            <div v-if="notice" class="alert alert-success">{{ notice }}</div>
            <div style="display: flex; justify-content: center;">
              <button type="submit">Gửi</button>
            </div>
            <br />
          </form>
          <div v-if="comments.length === 0">
            <ul class="list-group media-list media-list-stream">
              <li class="media list-group-item p-a">
                <div class="media-body">
                  <span>{{ authorName }} chưa có bình luận nào</span>
                  <br />
                  <small class="text-muted">Hãy là người đầu tiên bình luận</small>
                  <div class="clearfix"></div>
                </div>
              </li>
            </ul>
          </div>
          <div v-else class="container" style="max-height: 500px;; overflow-y: auto;">
            <div
              v-for="comment in comments"
              :key="comment.id"
              class="panel panel-default panel-profile m-b-md text-rights"
            >
              <ul class="list-group media-list media-list-stream">
                <li class="media list-group-item p-a">
                  <div class="media-body">
                    <span>{{ comment.content }}</span>
                    <br />
                    <small class="text-muted">{{ comment.timestamp }}</small>
                    <div class="clearfix"></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <TheRight />
        <TheRight2 />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TheRight from "./TheRight.vue";
import TheRight2 from "./TheRight2.vue";
export default {
  components: {
    TheRight,
    TheRight2
  },
  data() {
    return {
      author: [], // Initialize with an empty object
      authorName: this.$route.params.author,
      mangas: [],
      newComment: "",
      comments: [],
      notice: "",
      currentPage: 1,
      itemsPerPage: 8
    };
  },
  watch: {
    "$route.params.author": {
      immediate: true, // Để chạy ngay lập tức khi component được tạo
      handler(newName) {
        // Xử lý khi params thay đổi, ví dụ:
        this.authorName = newName;
        document.title = "Tác giả " + this.authorName;
        this.fetchauthorData(this.authorName); // Gọi hàm để fetch dữ liệu mới
      }
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.mangas.length / this.itemsPerPage);
    },
    paginatedManga() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.mangas.slice(start, end);
    },
    displayPages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;
      let pages = [];
      let startPage = 1;
      let endPage = totalPages;
      const maxVisiblePages = 5; // Số lượng trang hiển thị tối đa

      if (totalPages > maxVisiblePages) {
        if (currentPage <= 3) {
          endPage = maxVisiblePages;
        } else if (currentPage >= totalPages - 2) {
          startPage = totalPages - maxVisiblePages + 1;
        } else {
          startPage = currentPage - 2;
          endPage = currentPage + 2;
        }
      }

      if (startPage !== 1) {
        pages.push(1);
        if (startPage > 2) {
          pages.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage !== totalPages) {
        if (endPage < totalPages - 1) {
          pages.push("...");
        }
        pages.push(totalPages);
      }

      return pages;
    }
  },
  created() {
    document.title = "Tác giả " + this.authorName;
    axios
      .get(`https://chaosquare.shop/api/comments-author/${this.authorName}`)
      .then(response => {
        this.comments = response.data;
        // this.pageTitle = this.author.name;
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          // Chuyển hướng đến trang lỗi
          this.$router.push("/error");
        } else {
          console.error("Error fetching author:", error);
        }
      });
  },
  mounted() {
    this.fetchauthorData(this.authorName);
  },
  methods: {
    fetchauthorData(id) {
      axios
        .get(`https://chaosquare.shop/api/author/${id}`)
        .then(response => {
          if (response.data.error) {
            this.$router.push("/error");
          } else {
            this.author = response.data;
          }
          // this.pageTitle = this.author.name;
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            // Chuyển hướng đến trang lỗi
          } else {
            console.error("Error fetching author:", error);
          }
        });
      axios
        .get(`https://chaosquare.shop/api/author-manga/${id}`)
        .then(response => {
          this.mangas = response.data;
        })
        .catch(error => {
          console.error("Error fetching recommend authors:", error);
        });
    },
    submitComment() {
      axios
        .post(
          "https://chaosquare.shop/api/send-authorcomment",
          {
            authorName: this.authorName,
            newComment: this.newComment
          },
          {
            headers: {
              "X-CSRF-TOKEN": document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content")
            }
          }
        )
        .then(response => {
          if (response.data.success) {
            alert("Đăng bình luận thành công!");
            window.location.reload();
          } else {
            // Xử lý khi không thành công
            console.error("Đăng bình luận không thành công!'");
          }
        })
        .catch(error => {
          // Xử lý lỗi nếu có
          console.error("Error sending POST request:", error);
        });
    },
    truncatedName(name) {
      if (name.length > 20) {
        return name.substring(0, 20) + "...";
      }
      return name;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      if (page === "...") return;
      this.currentPage = page; // Cập nhật currentPage
      this.scrollToNew();
    },
    nextPageAndScroll() {
      this.nextPage();
      this.scrollToNew();
    },
    prevPageAndScroll() {
      this.prevPage();
      this.scrollToNew();
    },
    scrollToNew() {
      const element = document.getElementById("new");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }
};
</script>
<style scoped>
</style>