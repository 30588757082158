<template>
    <div>
        <h3 style="background: rgb(1,204,157); background: linear-gradient(90deg, rgba(1,204,157,1) 49%, rgba(166,3,201,1) 100%); padding: 5px">Top tác giả</h3>
        <div class="row" style="overflow-y: auto; max-height: 500px;">
            <div v-for="(user, index) in users" :key="index" class="row">
                <div class="col-lg-3" style="align-items: center; display: flex; justify-content: center;">{{ index + 1
                    }}
                </div>
                <div class="col-lg-9">
                    <p>Tên: <router-link :to="`/tac-gia/${user.username}`">{{ user.username }}</router-link></p>
                    <p>Số tác phẩm: {{ user.manga }}</p>
                    <p>Lượt thích: {{ user.likes }}</p>
                </div>
                <hr>
            </div>
            <hr>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'TheRight2',
    data() {
        return {
            users: [] // Khởi tạo mảng mangas để lưu trữ danh sách top manga
        };
    },
    mounted() {
        this.fetchTopMangas(); // Gọi hàm fetchTopMangas() khi component được mounted
    },
    methods: {
        fetchTopMangas() {
            axios.get('https://chaosquare.shop/api/top-users') // Gửi yêu cầu GET đến API của Laravel
                .then(response => {
                    this.users = response.data; // Lưu danh sách top manga vào biến mangas
                })
                .catch(error => {
                    console.error('Error fetching top mangas:', error);
                });
        }
    }
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
