<template>
  <footer
    class="py-3 my-4"
    style="background: rgb(1,204,157); background: linear-gradient(90deg, rgba(1,204,157,1) 0%, rgba(166,3,201,1) 100%);"
  >
    <ul class="nav justify-content-center border-bottom pb-3 mb-3">
      <li class="nav-item">
        <router-link to="/" class="nav-link px-2 text-body-secondary">Trang chủ</router-link>
      </li>

      <li class="nav-item">
        <a
          href="https://askmeprivate.io.vn/wall/Bùi%20Quang%20Minh"
          class="nav-link px-2 text-body-secondary"
          target="_blank"
        >Góp ý</a>
      </li>
      <li class="nav-item">
        <a
          href="https://www.facebook.com/meofhoaho"
          class="nav-link px-2 text-body-secondary"
          target="_blank"
        >Liên hệ</a>
      </li>
      <li class="nav-item">
        <a
          href="https://meof.io.vn"
          class="nav-link px-2 text-body-secondary"
          target="_blank"
        >Sản phẩm khác</a>
      </li>
    </ul>
    <p class="text-center text-body-secondary">© 2024 meof</p>
  </footer>
</template>
