<template>
  <div class="container" style="padding: 20px">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-12">
        <h5>
          <router-link to="/">Trang chủ</router-link>>
          <router-link :to="`/truyen/${manga.name}`">
            {{
            manga.name }}
          </router-link>
        </h5>
        <hr />
        <h3 style="text-align: center;">{{ manga.name }}</h3>
        <div class="row">
          <div class="col-lg-4 col-md-12 col-12">
            <img
              :src="`/images/${manga.path}`"
              style="justify-content: center;align-items: center;width: 250px; height: 400px; margin: auto; display: flex;"
              alt="test.jpg"
            />
          </div>
          <div class="col-lg-8 col-md-12 col-12">
            <div style="padding: 10px;">
              <p>Tên khác: {{ manga.othername ? manga.othername : 'Không có' }}</p>
              <p>
                Tác giả:
                <router-link :to="`/tac-gia/${manga.by}`">{{ manga.by }}</router-link>
              </p>
              <p>Mô tả:</p>
              <div style="overflow-y: auto;max-height: 100px;">
                <p>{{ manga.description }}</p>
              </div>
              <br />
              <p>Thể loại: {{ manga.type }}</p>
              <p>Lượt xem: {{ manga.views }}</p>
              <p>Lượt bình luận: {{ totalcomment }}</p>
              <div>
                <button type="button" class="btn btn-danger" @click="follow">Theo dõi</button>
                Lượt theo dõi: {{ manga.likes }}
              </div>
            </div>
          </div>
          <hr />
          <div style="text-align: center;" v-if="chapter && chapter.length > 0">
            <router-link
              :to="`/truyen/${manga.name}/${chapter[chapter.length - 1].chapter}`"
              type="button"
              class="btn btn-warning"
            >Đọc từ đầu</router-link>
            <router-link
              :to="`/truyen/${manga.name}/${chapter[0].chapter}`"
              type="button"
              class="btn btn-warning"
            >Đọc mới nhất</router-link>
          </div>
          <div>
            <b>Danh sách chương</b>
            <br>
            <br />
            <div style="max-height: 300px; overflow-y: auto;">
              <div v-for="(chap, index) in chapter" :key="index">
                <p>
                  <router-link :to="`/truyen/${manga.name}/${chap.chapter}`">
                    Chương
                    {{ chap.chapter }}: {{ chap.name }}
                    <hr />
                  </router-link>
                </p>
              </div>
            </div>
          </div>
          <!-- <TheComment /> -->
          <div>
            <h3>Bình luận:</h3>
            <form @submit.prevent="submitComment">
              <textarea
                v-model="newComment"
                rows="6"
                style="width: 100%;"
                placeholder="Nhập bình luận"
                required
              ></textarea>
              <div v-if="notice" class="alert alert-success">{{ notice }}</div>
              <div style="display: flex; justify-content: center;">
                <button type="submit">Gửi</button>
              </div>
              <br />
            </form>
            <div v-if="comments.length === 0">
              <ul class="list-group media-list media-list-stream">
                <li class="media list-group-item p-a">
                  <div class="media-body">
                    <span>{{ mangaName }} chưa có bình luận nào</span>
                    <br />
                    <small class="text-muted">Hãy là người đầu tiên bình luận</small>
                    <div class="clearfix"></div>
                  </div>
                </li>
              </ul>
            </div>
            <div v-else class="container" style="max-height: 500px;; overflow-y: auto;">
              <div
                v-for="comment in comments"
                :key="comment.id"
                class="panel panel-default panel-profile m-b-md text-rights"
              >
                <ul class="list-group media-list media-list-stream">
                  <li class="media list-group-item p-a">
                    <div class="media-body">
                      <span>{{ comment.content }}</span>
                      <br />
                      <small class="text-muted">{{ comment.timestamp }}</small>
                      <div class="clearfix"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <TheRight />
        <br>
        <br>
        <br>
        <TheRight2 />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TheRight from "./TheRight.vue";
import TheRight2 from "./TheRight2.vue";
export default {
  components: {
    TheRight,
    TheRight2
  },
  data() {
    return {
      manga: {}, // Initialize with an empty object
      mangaName: this.$route.params.name,
      chapter: [],
      newComment: "",
      comments: [],
      notice: "",
      totalcomment: 0
    };
  },
  watch: {
    "$route.params.name": {
      immediate: true, // Để chạy ngay lập tức khi component được tạo
      handler(newName) {
        // Xử lý khi params thay đổi, ví dụ:
        this.mangaName = newName;
        document.title = this.mangaName;
        this.fetchMangaData(this.mangaName); // Gọi hàm để fetch dữ liệu mới
      }
    }
  },

  created() {
    document.title = this.mangaName;
    axios
      .get(`https://chaosquare.shop/api/comments/${this.mangaName}`)
      .then(response => {
        this.comments = response.data;
        // this.pageTitle = this.manga.name;
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          // Chuyển hướng đến trang lỗi
          this.$router.push("/error");
        } else {
          console.error("Error fetching manga:", error);
        }
      });
  },
  mounted() {
    this.fetchMangaData(this.mangaName);
  },
  methods: {
    follow() {
      if (!localStorage.getItem("username")) {
        alert("Bạn phải đăng nhập mới có thể theo dõi!");
      } else {
        axios
          .post(
            "https://chaosquare.shop/api/follow",
            {
              username: localStorage.getItem("username"),
              manga: this.mangaName
            },
            {
              headers: {
                "X-CSRF-TOKEN": document
                  .querySelector('meta[name="csrf-token"]')
                  .getAttribute("content")
              }
            }
          )
          .then(response => {
            if (response.data.msg) {
              alert(response.data.msg);
            }
          })
          .catch(error => {
            // Xử lý lỗi nếu có
            console.error(error);
          });
      }
    },
    fetchMangaData(id) {
      axios
        .get(`https://chaosquare.shop/api/manga/${id}`)
        .then(response => {
          if (response.data.error) {
            this.$router.push("/error");
          } else {
            this.manga = response.data.manga;
            this.totalcomment = response.data.totalcomment;
            axios
              .get(`https://chaosquare.shop/api/chap/${id}`)
              .then(response => {
                this.chapter = response.data;
              })
              .catch(error => {
                console.error("Error fetching recommend mangas:", error);
              });
          }
          // this.pageTitle = this.manga.name;
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            // Chuyển hướng đến trang lỗi
          } else {
            console.error("Error fetching manga:", error);
          }
        });
    },
    submitComment() {
      axios
        .post(
          "https://chaosquare.shop/api/send-comment",
          {
            mangaName: this.mangaName,
            newComment: this.newComment
          },
          {
            headers: {
              "X-CSRF-TOKEN": document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content")
            }
          }
        )
        .then(response => {
          if (response.data.success) {
            alert("Đăng bình luận thành công!");
            window.location.reload();
          } else {
            // Xử lý khi không thành công
            console.error("Đăng bình luận không thành công!'");
          }
        })
        .catch(error => {
          // Xử lý lỗi nếu có
          console.error("Error sending POST request:", error);
        });
    }
  }
};
</script>