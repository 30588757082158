<template>
    <div>
        <h3 style="background: rgb(1,204,157); background: linear-gradient(90deg, rgba(1,204,157,1) 49%, rgba(166,3,201,1) 100%); padding: 5px;">Top truyện hay</h3>
        <div class="row" style="overflow-y: auto; max-height: 600px;">
            <div v-for="topmanga in topmangas" :key="topmanga.id" class="row">
                <div class="col-lg-4 col-md-4 col-4" style="justify-content: center;"><div class="card">
                    <router-link :to="`/truyen/${topmanga.name}`"><img :src="`/images/${topmanga.path}`" style="justify-content: center;width: 100%; height: 100px;"
                        class="card-img-top" :alt="topmanga.name"></router-link></div>
                </div>
                <div class="col-lg-8 col-md-8 col-8">
                    <p>Tên: <router-link :to="`/truyen/${topmanga.name}`">{{ topmanga.name }}</router-link></p>
                    <p>Tác giả: <router-link :to="`/tac-gia/${topmanga.by}`">{{ topmanga.by }}</router-link></p>
                    <p>Thể loại: {{ topmanga.type || 'Tất cả' }}</p>
                    <p>Mô tả:
                        <span v-if="topmanga.description.length > 80">
                            {{ topmanga.description.substring(0, 80) }}...
                        </span>
                        <span v-else>
                            {{ topmanga.description }}
                        </span>
                    </p>
                    <p>Lượt xem: {{ topmanga.views }}</p>
                    <p>Lượt thích: {{ topmanga.totalLikes }}</p>
                    <p>Lượt bình luận: {{ topmanga.totalComments }}</p>
                </div>
                <hr>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'TheRight',
    data() {
        return {
            topmangas: [] // Khởi tạo mảng mangas để lưu trữ danh sách top manga
            
        };
    },
    mounted() {
        this.fetchTopMangas(); // Gọi hàm fetchTopMangas() khi component được mounted
    },
    
    methods: {
        fetchTopMangas() {
            axios.get('https://chaosquare.shop/api/top-mangas') // Gửi yêu cầu GET đến API của Laravel
                .then(response => {
                    this.topmangas = response.data; // Lưu danh sách top manga vào biến mangas
                })
                .catch(error => {
                    console.error('Error fetching top mangas:', error);
                });
        }
    }
};
</script>

<style>
/* Add your custom styles here */
a {
  text-decoration: none;
}
</style>
