<template>
    <div class="container" style="padding: 20px">
        <div class="row">
            <div class="col-lg-8 col-md-8 col-12">
                <h5>
                    <router-link to="/">Trang chủ</router-link>
                    <span>></span>
                    <router-link to="/login">Đăng nhập</router-link>
                </h5>
                <div class="container">
                    <h1>Đăng nhập</h1>
                    <form @submit.prevent="login">
                        <div class="mb-3">
                            <label for="username" class="form-label">Tên đăng nhập<span class="rq">*</span></label>
                            <input type="text" class="form-control" id="username" v-model="username" @input="clearError" required
                                autofocus />
                        </div>
                        <div class="mb-3">
                            <label for="password" class="form-label">Mật khẩu<span class="rq">*</span></label>
                            <input type="password" class="form-control" id="password" v-model="password" @input="clearError" required />
                        </div>
                        <br v-if="!error">
                        <div v-if="error" style="color: red">{{ error }}</div>
                        <div>Chưa có tài khoản? <router-link to="/dang-ky">Đăng ký ngay</router-link></div>
                        <div>
                            <router-link to="/forget">Quên mật khẩu/tài khoản</router-link>
                        </div>
                        <hr />
                        <button type="submit" class="btn btn-primary">Đăng nhập</button>
                    </form>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <TheRight />
            </div>
        </div>
    </div>
</template>
<script>
import TheRight from './TheRight.vue';
import axios from 'axios';
export default {
    components: {
        TheRight,
    },
    data() {
        return {
            username: '',
            password: '',
            error: null,
            pageTitle: 'Truyện chữ nét | Đăng nhập'
        };
    },
    created() {
        // Kiểm tra nếu đã có username trong localStorage, điều hướng về trang chủ
        if (localStorage.getItem('username')) {
            alert('Bạn đã đăng nhập!');
            this.$router.push('/');
        }
        // Gán tittle cho page
        document.title = this.pageTitle;
    },
    methods: {
        clearError() {
            this.error = null;
        },
        login() {
            axios.post('https://chaosquare.shop/api/login', {
                username: this.username,
                password: this.password,
            }, {
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            })
                .then(response => {
                    console.log(this.username, this.password);
                    if (response.data.success) {
                        // Xử lý khi thành công
                        // this.error = "Đăng nhập thành công";
                        localStorage.setItem('username', this.username);
                        console.log('Login successful: ' + localStorage.getItem('username'));
                        alert('Đăng nhập thành công!');
                        window.location.href = '/';
                    } else {
                        // Xử lý khi không thành công
                        this.error = response.data.msg;
                    }
                })
                .catch(error => {
                    // Xử lý lỗi nếu có
                    console.error(error);
                });
        }
    }
};
</script>

<style scoped>
/* Add your styles here */
.rq {
    color: red
}
</style>