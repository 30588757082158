<template>
  <div class="container" style="padding: 20px">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-12">
        <h5>
          <router-link to="/">Trang chủ</router-link>>
          <router-link to="/register">Đăng ký</router-link>
        </h5>
        <div class="container">
          <h1>Đăng ký</h1>
          <form @submit.prevent="register">
            <div class="mb-3">
              <label for="username" class="form-label">
                Tên người dùng
                <span class="rq">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                id="username"
                name="username"
                placeholder="Nhập tên người dùng"
                v-model="username"
                required
                autofocus
              />
              <div id="usernamelHelp" class="form-text">
                Tên đăng nhập phải
                có độ dài từ 5-17 kí tự (không được có dấu cách)
              </div>
              <span id="errorusername" style="color: red; display: none;">
                Tên đăng nhập phải
                có độ dài từ 5-17 kí tự và không được có dấu cách
                <br />
              </span>
              <div style="color: red; display: none;">Tên người đùng đã tồn tại.</div>
            </div>
            <div class="mb-3">
              <label for="email" class="form-label">
                Email
                <span class="rq">*</span>
              </label>
              <div class="input-group">
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  name="email"
                  v-model="email"
                  placeholder="Nhập email đăng kí"
                  required
                />
                <button
                  class="btn btn-outline-secondary"
                  id="submitButton"
                  type="button"
                  @click="emailCaptcha"
                >
                  Nhận
                  mã
                </button>
              </div>
              <div id="emailHelp" class="form-text">Email dùng để thay đổi mật khẩu</div>
              <div style="color: red; display: none;">Email đã tồn tại.</div>
            </div>
            <div class="mb-3">
              <label for="captcha" class="form-label">
                Mã xác thực Email
                <span class="rq">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                id="captcha"
                aria-describedby="emailHelp"
                name="captcha"
                v-model="captcha"
                placeholder="6 chữ số của mã xác thực"
                required
              />
              <div id="emailHelp" class="form-text">
                Vui lòng ấn vào gửi mã, và lấy mã trong email
                (có thể kiểm
                tra thư mục thư rác)
              </div>
              <div style="color: red; display: none;">Mã xác thực không đúng</div>
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">
                Mật khẩu
                <span class="rq">*</span>
              </label>
              <input
                type="password"
                class="form-control"
                id="password"
                name="password"
                v-model="password"
                required
              />
              <div id="passwordHelp" class="form-text">
                Mật khẩu phải có ít
                nhất 6 kí tự
              </div>
            </div>
            <div class="mb-3">
              <label for="re_password" class="form-label">
                Nhập lại mật
                khẩu
                <span class="rq">*</span>
              </label>
              <input
                type="password"
                class="form-control"
                name="repassword"
                id="repassword"
                v-model="repassword"
                required
              />
            </div>
            <span id="error_message" style="color: red; display: none;">
              Bạn
              phải nhập lại đúng mật khẩu
              <br />
            </span>
            <span id="passwordErrorMessage" style="color: red; display: none;">
              Mật khẩu phải có ít
              nhất 6 kí tự
              <br />
            </span>
            <div>
              Đã có tài khoản?
              <router-link to="/dang-nhap">Đăng nhập ngay</router-link>
            </div>
            <button type="submit" class="btn btn-primary">
              Đăng
              ký
            </button>
          </form>
        </div>
        <br />
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <TheRight2 />
      </div>
    </div>
  </div>
</template>
<script>
import TheRight2 from "./TheRight2.vue";
import axios from "axios";
export default {
  components: {
    TheRight2
  },
  data() {
    return {
      username: "",
      password: "",
      repassword: "",
      email: "",
      pageTitle: "Truyện chữ nét | Đăng ký",
      captcha: ""
    };
  },
  created() {
    // Kiểm tra nếu đã có username trong localStorage, điều hướng về trang chủ
    if (localStorage.getItem("username")) {
      alert("Bạn đã đăng nhập!");
      this.$router.push("/");
    }
    document.title = this.pageTitle;
  },
  methods: {
    emailCaptcha() {
      axios
        .post(
          "https://chaosquare.shop/api/email-captcha",
          {
            email: this.email
          },
          {
            headers: {
              "X-CSRF-TOKEN": document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content")
            }
          }
        )
        .then(response => {
          if (response.data.success) {
            alert("Gửi mã thành công! Vui lòng kiểm tra email");
          } else {
            // Xử lý khi không thành công
            alert(response.data.msg);
          }
        })
        .catch(error => {
          // Xử lý lỗi mà không cần sử dụng thông tin chi tiết của error
          console.error(error);
        });
    },
    register() {
      axios
        .post(
          "https://chaosquare.shop/api/register",
          {
            username: this.username,
            email: this.email,
            captcha: this.captcha,
            password: this.password,
            repassword: this.repassword
          },
          {
            headers: {
              "X-CSRF-TOKEN": document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content")
            }
          }
        )
        .then(response => {
          if (response.data.success) {
            alert("Đăng kí thành công!");
            this.$router.push("/login");
          } else {
            // Xử lý khi không thành công
            console.log(this.password, this.repassword);
            alert(response.data.msg);
          }
        })
        .catch(error => {
          // Xử lý lỗi mà không cần sử dụng thông tin chi tiết của error
          console.error(error);
        });
    }
  }
};
</script>
<style scoped>
.rq {
  color: red;
}
</style>