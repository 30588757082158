<template>
  <div class="container" style="padding: 20px">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-12">
        <!-- Nội dung của trang lỗi 404 -->
        <div class="text-center">
          <h1 class="display-1">404</h1>
          <h2>Oops! Trang không tìm thấy.</h2>
          <p>Rất tiếc, nhưng trang bạn đang tìm kiếm không tồn tại hoặc đã bị di chuyển.</p>
          <button @click="goBack" class="btn btn-primary">Quay lại trước đó</button>
          <br>
          <br>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <TheRight />
      </div>
    </div>
  </div>
</template>
  
  <script>
import TheRight from "./TheRight.vue";

export default {
  components: {
    TheRight
  },
  methods: {
    goBack() {
      this.$router.go(-2);
    }
  }
};
</script>
  
  <style scoped>
.text-center {
  text-align: center;
  margin-top: 50px;
}

.display-1 {
  font-size: 10rem;
  font-weight: 700;
}

h2 {
  margin-top: 20px;
}

p {
  margin: 20px 0;
}
</style>
  