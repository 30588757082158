<template>
  <div>
    <div class="container" style="padding: 20px">
      <div class="row">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <h5>
              <router-link to="/">Truyện</router-link>>
              <router-link :to="`/truyen/${mangaName}`">
                {{
                mangaName }}
              </router-link>>
              <router-link
                :to="`/truyen/${mangaName}/${chapter.chapter}`"
              >Chương {{ chapter.chapter }}</router-link>
            </h5>
            <br />
            <div style="text-align: center;">
              <select
                id="selectChapter"
                @change="goToChapter"
                v-model="chap"
                class="btn btn-secondary"
              >
                <option
                  v-for="c in allchap"
                  :key="c.chapter"
                  :value="c.chapter"
                >Chương {{ c.chapter }}</option>
              </select>
            </div>
            <br />
            <hr />
            <h5>Chương {{ chapter.chapter }}: {{ chapter.name }}</h5>
            <div>
              <p v-html="renderedContent"></p>
              <button v-show="isVisible" @click="scrollToTop" class="scroll-to-top">⬆️</button>
            </div>
            <br />
            <div style="text-align: center;">
              <select
                id="selectChapter"
                @change="goToChapter"
                v-model="chap"
                class="btn btn-secondary"
              >
                <option
                  v-for="c in allchap"
                  :key="c.chapter"
                  :value="c.chapter"
                >Chương {{ c.chapter }}</option>
              </select>
            </div>
            <br />
            <hr />
            <!-- <TheComment /> -->
            <div>
              <h3>Bình luận:</h3>
              <form @submit.prevent="submitComment">
                <textarea
                  v-model="newComment"
                  rows="6"
                  style="width: 100%;"
                  placeholder="Nhập bình luận"
                  required
                ></textarea>
                <div v-if="notice" class="alert alert-success">{{ notice }}</div>
                <div style="display: flex; justify-content: center;">
                  <button type="submit">Gửi</button>
                </div>
                <br />
              </form>
              <div v-if="comments.length === 0">
                <ul class="list-group media-list media-list-stream">
                  <li class="media list-group-item p-a">
                    <div class="media-body">
                      <span>{{ mangaName }} chưa có bình luận nào</span>
                      <br />
                      <small class="text-muted">Hãy là người đầu tiên bình luận</small>
                      <div class="clearfix"></div>
                    </div>
                  </li>
                </ul>
              </div>
              <div v-else class="container" style="max-height: 500px;; overflow-y: auto;">
                <div
                  v-for="comment in comments"
                  :key="comment.id"
                  class="panel panel-default panel-profile m-b-md text-rights"
                >
                  <ul class="list-group media-list media-list-stream">
                    <li class="media list-group-item p-a">
                      <div class="media-body">
                        <span>{{ comment.content }}</span>
                        <br />
                        <small class="text-muted">{{ comment.timestamp }}</small>
                        <div class="clearfix"></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import TheComment from './TheComment.vue';
export default {
  components: {
    // TheComment,
  },
  data() {
    return {
      pageTitle: "default",
      mangaName: this.$route.params.name,
      chap: this.$route.params.chap,
      chapter: {},
      allchap: [],
      manga: {},
      comments: [],
      renderedContent: "",
      isVisible: false
    };
  },
  watch: {
    "$route.params.chap": {
      immediate: true, // Để chạy ngay lập tức khi component được tạo
      handler(newName) {
        // Xử lý khi params thay đổi, ví dụ:
        this.chap = newName;
        this.fetchMangaData(this.mangaName, this.chap);
      }
    }
  },
  created() {
    document.title = this.mangaName;
    axios
      .get(`https://chaosquare.shop/api/comments/${this.mangaName}`)
      .then(response => {
        this.comments = response.data;
        // this.pageTitle = this.manga.name;
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          // Chuyển hướng đến trang lỗi
          this.$router.push("/error");
        } else {
          console.error("Error fetching manga:", error);
        }
      });
    axios
      .get(`https://chaosquare.shop/api/chap/${this.mangaName}`)
      .then(response => {
        this.allchap = response.data;
        // this.pageTitle = this.manga.name;
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          // Chuyển hướng đến trang lỗi
          this.$router.push("/error");
        } else {
          console.error("Error fetching manga:", error);
        }
      });
  },
  mounted() {
    this.fetchMangaData(this.mangaName, this.chap);
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    //`https://chaosquare.shop/api/manga/${id}`
    fetchMangaData(id, chap) {
      axios
        .get(`https://chaosquare.shop/api/manga/${id}`)
        .then(response => {
          if (response.data.error) {
            this.$router.push("/error");
          } else {
            this.manga = response.data;
            this.pageTitle = this.manga.name;
            axios
              .get(`https://chaosquare.shop/api/manga/${id}/${chap}`)
              .then(response => {
                if (response.data.error) {
                  this.$router.push("/error");
                } else {
                  this.chapter = response.data;
                  this.renderedContent = this.renderHtml(this.chapter.content);
                }
              })
              .catch(error => {
                console.error("Error fetching recommend mangas:", error);
              });
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            // Chuyển hướng đến trang lỗi
            this.$router.push("/error");
          } else {
            console.error("Error fetching manga:", error);
          }
        });
    },
    goToChapter() {
      // Logic để chuyển đến chapter đã chọn
      console.log("Selected Chapter:", this.selectedChapter);
      // Bạn có thể sử dụng Vue Router để chuyển hướng

      this.$router.push(`/truyen/${this.mangaName}/${this.chap}`);
    },
    renderHtml(content) {
      // Hàm render HTML an toàn
      // Sử dụng replace để thay thế dòng xuống dòng mới thành thẻ <br>
      return content.replace(/(?:\r\n|\r|\n)/g, "<br>");
    },
    submitComment() {
      axios
        .post(
          "https://chaosquare.shop/api/send-comment",
          {
            mangaName: this.mangaName,
            newComment: this.newComment
          },
          {
            headers: {
              "X-CSRF-TOKEN": document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content")
            }
          }
        )
        .then(response => {
          console.log(this.mangaName, this.mangaName);
          if (response.data.success) {
            alert("Đăng bình luận thành công!");
            window.location.reload();
          } else {
            // Xử lý khi không thành công
            console.error("Đăng bình luận không thành công!'");
          }
        })
        .catch(error => {
          // Xử lý lỗi nếu có
          console.error("Error sending POST request:", error);
        });
    },
    handleScroll() {
      this.isVisible = window.scrollY > 100; // Hiển thị nút khi cuộn xuống hơn 200px
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Cuộn lên mượt mà
      });
    },
  }
};
</script>
<style>
.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 15px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease-in-out;
}
.scroll-to-top:hover {
  background-color: #0056b3;
}
</style>