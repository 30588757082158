<template>
  <div>
    <Navbar />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Navbar from "./views/layouts/TheNavbar.vue";
import Footer from "./views/layouts/TheFooter.vue";
export default {
  name: "App",
  components: {
    Navbar,
    Footer
  }
};
</script>
<style>
a {
  text-decoration: none;
}
</style>